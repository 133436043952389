<template>
  <div class="auth-content">

    <form @submit.prevent="send" action="" class="auth__form app-form">
      <div class="app-form__header">Вход</div>
      <div class="app-form__content">
        <div class="app-form__field-list">
          <div v-for="(field, index) in formData.fields" :key="index" class="app-form__field-item">
            <div class="app-form__field-name">{{ field.title }}</div>
            <app-input
                v-model="field.value"
                :placeholder="field.placeholder"
                :name="field.name"
                :type="field.type"
                :class-name="'app-form__field'"
            />
          </div>
        </div>
        <app-button :value="'Отправить'" :type="'submit'" :class-name="'app-form__button'" />
      </div>
      <div class="app-form__description">
        Вход в приложение доступен напрямую из административной части (бек-офис) insales.
      </div>
    </form>

    <app-message
        :class-name="'app-form__message'"
        ref="app-message"
    />

  </div>
</template>

<script>
import appInput from '../../components/base-ui/app-input';
import appButton from '../../components/base-ui/app-button';
import appMessage from '../../components/base-ui/app-message';
export default {
  name: 'login',
  data: function () {
    return {
      formData: {
        title: 'Вход',
        fields: {
          login: {
            title: 'Логин',
            placeholder: 'Логин',
            type: 'text',
            name: 'shopId',
            value: ''
          },
          password: {
            title: 'Пароль',
            placeholder: 'Пароль',
            type: 'password',
            name: 'password',
            value: ''
          }
        }
      }
    }
  },
  methods: {
    createMessage: function (opts) {
      this.$refs['app-message'].open({
        type: opts.type,
        message: opts.message,
        description: opts.description
      });
    },
    send: async function () {
      this.$store.dispatch('loginAccount', { password: this.formData.fields.password.value, login: this.formData.fields.login.value })
          .then(response => {
            if (response.result && response.result === 'success') {
              console.log('Redirect');

              this.$router.push({ path: '/'});
              return;
            }

            if (response.message === 'Unauthorized') {
              this.createMessage({
                type: 'warning',
                message: 'Неверный логин или пароль'
              })
              return;
            }

            throw new Error(response);
          })
          .catch(() => {
            this.createMessage({
              type: 'warning',
              message: 'Неизвестная ошибка. Повторите попытку'
            })
          })
    }
  },
  components: {
    'app-input': appInput,
    'app-button': appButton,
    'app-message': appMessage,
  }
}
</script>


<style lang="scss">
.auth-content {
  position: relative;
}
</style>
